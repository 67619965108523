import React from "react";

const Mainslider = () => {
  return (
    <>
      <div className="main-image hdtx-main-image">
          <div className="container">
            <h2>PT. Panasia Indo Resouces Tbk.</h2>
            <hr />
            <p>
              Jl. Moh. Toha Km 6 Bandung, Jawa Barat, Indonesia <br/>
              Tel: 022 - 5202930, Faksimili : 022 - 5205881
            </p>
          </div>
      </div>
    </>
  );
};

export default Mainslider;
