import React from "react";

const History = () => {
  
  return (
    <>
      <div className="history-area sec-p-100">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>Riwayat Singkat</h2>
              <p>
              Perusahaan didirikan pada tanggal 6 April 1973, dengan nama PT Harapan Djaja 4 Saudara dengan lokasi pabrik terletak di Bandung. <br />
              <br />
              Perusahaan telah beberapa kali mengalami perubahan nama dan terakhir sejak 5 September 2012, Perusahaan berganti nama menjadi PT Panasia Indo Resources Tbk. 
              </p>
            </div>
            <div className="col-md-6">
              <h2 className="eng-version">History</h2>
              <p className="eng-version">
                The Company was incorporated on April 6, 1973, named PT Harapan Djaja 4 Saudara with the factory located in Bandung. <br />
                <br />
                The Company has several times changed of name and since September 5, 2012, the Company officially changed its name into PT Panasia Indo Resources Tbk. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default History;
