import React from "react";

const Quote = () => {
  return (
    <>
      <div className="visi-misi sec-p-100 bg-color3">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>Visi dan Misi Perusahaan</h2>
              <h4>Misi:</h4>
              <p>
                Menjadi perusahaan yang memproduksi benang polyester yang memiliki varian cukup banyak untuk mendapatkan nilai tambah.
              </p>
              <hr />
              <h4>Visi:</h4>
              <p className="mb-40">
                Mempertahankan bisnis utama sebagai produsen benang polyester dan menjadi holding company perusahaan yang bergerak di bidang sumber daya alam yang ada di Indonesia
              </p>
            </div>
            <div className="col-md-6">
              <h2 className="eng-version">Vision and Missions the Company</h2>
              <h4 className="eng-version">Missions</h4>
              <p className="eng-version">
                “Being a company that manufactures polyester yarn which has quite a lot of variants to obtain added value"
              </p>
              <hr />
              <h4 className="eng-version">Vision</h4>
              <p className="eng-version">
                “Maintaining the main business as a manufacturer of polyester yarn and being a holding company for enterprise that engaged in the of natural resources in Indonesia” 
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quote;
