import React from "react";

const Quote = () => {
  return (
    <>
      <div className="visi-misi sec-p-100 bg-color3">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>Visi dan Misi Perusahaan</h2>
              <h4>Misi:</h4>
              <p>
                Mencari peluang usaha baru yang dapat memberikan kontribusi positif
              </p>
              <hr />
              <h4>Visi:</h4>
              <p className="mb-40">
                Menjadi perusahaan holding distribusi berbagai macam produk
              </p>
            </div>
            <div className="col-md-6">
              <h2 className="eng-version">Vision and Missions the Company</h2>
              <h4 className="eng-version">Missions</h4>
              <p className="eng-version">
                “Finding new business opportunities that can make a positive contribution.” 
              </p>
              <hr />
              <h4 className="eng-version">Vision</h4>
              <p className="eng-version">
                “To become a holding company distribution range of products"
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quote;
