import React from "react";
import TinySlider from "tiny-slider-react";

import img1 from "../../img/HDTX/1.png"
import img2 from "../../img/HDTX/2.png"
import img3 from "../../img/HDTX/3.png"
import img4 from "../../img/HDTX/4.png"
import img5 from "../../img/HDTX/5.png"
import img6 from "../../img/HDTX/6.png"
import img7 from "../../img/HDTX/7.png"
import img8 from "../../img/HDTX/8.png"
import img9 from "../../img/HDTX/9.png"
import img10 from "../../img/HDTX/10.png"
import img11 from "../../img/HDTX/11.png"
import img12 from "../../img/HDTX/12.png"

const BusinessProducts = () => {
  const options = {
    items: 3,
    controls: false,
    autoplay: true,
    autoplayButtonOutput: false,
    mouseDrag: true,
    nav: false,
  };

  return (
    <>
      <div className="business-products-area sec-p-100">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>Kegiatan usaha dan produk yang dihasilkan</h2>
              <p className="mb-40">Sesuai Anggaran Dasar, kegiatan utama Perusahaan ialah menjalankan usaha dalam bidang industri khususnya industri tekstil dan investasi. <br/>
              Perusahaan menerima jasa maklun benang tekstur dan benang spun serta mulai menjajaki bisnis garmen dan penyewaan gudang.</p>
            </div>
            <div className="col-md-6">
              <h2 className="eng-version">Business and Product</h2>
              <p className="eng-version">Corresponding Articles of Association, the Company's principal activity is to carry on business in the field of industry, especially the textile industry and investment.<br/>
              The Company received the subcontract order of textured yarn and spun yarn and began exploring the garment business and warehouse rental.</p>
            </div>
          </div>
        </div>
        <div className="products-container bg-color1">
          <TinySlider settings={options}>
            <div className="product-image-container">
              <img
                alt="products"
                src={img1}
              />
            </div>
            <div className="product-image-container">
              <img
                alt="products"
                src={img2}
              />
            </div>
            <div className="product-image-container">
              <img
                alt="products"
                src={img3}
              />
            </div>
            <div className="product-image-container">
              <img
                alt="products"
                src={img4}
              />
            </div>
            <div className="product-image-container">
              <img
                alt="products"
                src={img5}
              />
            </div>
            <div className="product-image-container">
              <img
                alt="products"
                src={img6}
              />
            </div>
            <div className="product-image-container">
              <img
                alt="products"
                src={img7}
              />
            </div>
            <div className="product-image-container">
              <img
                alt="products"
                src={img8}
              />
            </div>
            <div className="product-image-container">
              <img
                alt="products"
                src={img9}
              />
            </div>
            <div className="product-image-container">
              <img
                alt="products"
                src={img10}
              />
            </div>
            <div className="product-image-container">
              <img
                alt="products"
                src={img11}
              />
            </div>
            <div className="product-image-container">
              <img
                alt="products"
                src={img12}
              />
            </div>
          </TinySlider>
        </div>
      </div>
    </>
  );
};

export default BusinessProducts;
