import React from "react";
import TinySlider from "tiny-slider-react";

import img1 from "../../img/PAFI/1.png"
import img2 from "../../img/PAFI/2.png"
import img3 from "../../img/PAFI/3.png"
import img4 from "../../img/PAFI/4.png"
import img5 from "../../img/PAFI/5.png"
import img6 from "../../img/PAFI/6.png"
import img7 from "../../img/PAFI/7.png"
import img8 from "../../img/PAFI/8.png"
import img9 from "../../img/PAFI/9.png"
import img10 from "../../img/PAFI/10.png"

const BusinessProducts = () => {
  const options = {
    items: 3,
    controls: false,
    autoplay: true,
    autoplayButtonOutput: false,
    mouseDrag: true,
    nav: false,
  };

  return (
    <>
      <div className="business-products-area sec-p-100">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>Kegiatan usaha dan produk yang dihasilkan</h2>
              <p className="mb-40">
                Sesuai anggaran dasar, Perusahaan menjalankan usaha di bidang usaha industri pemintalan benang tekstil, pertenunan, processing, dan perdagangan umum. <br />
                <br />
                Perusahaan menjalankan usaha penyewaan mesin - mesin dan gedung yang dimiliki. Produk yang dihasilkan Perusahaan adalah jasa yaitu penyewaan mesin dan gedung.
              </p>
            </div>
            <div className="col-md-6">
              <h2 className="eng-version">Business and Product</h2>
              <p className="eng-version">
                Appropriate Articles of Associations, the Company carries on business in the field of industry of textile yarn spinning, weaving, processing, and general trading.<br/>
                <br/>
                The Company carries on business of renting out machinery and buildings owned. The resulting product of Company is services of leasing of machinery and buildings.
              </p>
            </div>
          </div>
        </div>
        <div className="products-container bg-color1">
          <TinySlider settings={options}>
            <div className="product-image-container">
              <img
                alt="products"
                src={img1}
              />
            </div>
            <div className="product-image-container">
              <img
                alt="products"
                src={img2}
              />
            </div>
            <div className="product-image-container">
              <img
                alt="products"
                src={img3}
              />
            </div>
            <div className="product-image-container">
              <img
                alt="products"
                src={img4}
              />
            </div>
            <div className="product-image-container">
              <img
                alt="products"
                src={img5}
              />
            </div>
            <div className="product-image-container">
              <img
                alt="products"
                src={img6}
              />
            </div>
            <div className="product-image-container">
              <img
                alt="products"
                src={img7}
              />
            </div>
            <div className="product-image-container">
              <img
                alt="products"
                src={img8}
              />
            </div>
            <div className="product-image-container">
              <img
                alt="products"
                src={img9}
              />
            </div>
            <div className="product-image-container">
              <img
                alt="products"
                src={img10}
              />
            </div>
          </TinySlider>
        </div>
      </div>
    </>
  );
};

export default BusinessProducts;
