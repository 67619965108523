import React from "react";

import MainsImage from "../components/HDTX/MainImage";
import History from "../components/HDTX/History";
import VisiMisi from "../components/HDTX/Visi-Misi";
import BusinessProducts from "../components/HDTX/Business-Products";
import OrganizationChart from "../components/HDTX/OrganizationChart";

import PafiMainsImage from "../components/PAFI/MainImage";
import PafiHistory from "../components/PAFI/History";
import PafiVisiMisi from "../components/PAFI/Visi-Misi";
import PafiBusinessProducts from "../components/PAFI/Business-Products";
import PafiOrganizationChart from "../components/PAFI/OrganizationChart";

import Downloadable from "../components/Downloadable";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";

const Home = () => {
  return (
    <>
      <MainsImage />
      <History />
      <VisiMisi />
      <BusinessProducts />
      <OrganizationChart />

      <hr className="separet-border mb-60" />
      
      <PafiMainsImage />
      <PafiHistory />
      <PafiVisiMisi />
      <PafiBusinessProducts />
      <PafiOrganizationChart />

      <hr className="separet-border mb-60" />
      
      <Downloadable/>
      <Footer />
      <Scrolltop />
    </>
  );
};

export default Home;
