import React from "react";
const Footer = () => {
  return (
    <>
      <div className="footer-area bg-color2 sec-p-50 mt-100 text-white">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="footer-widget widget-1">
                <div>
                  <h3>PT. Panasia Indo Resouces Tbk.</h3>
                </div>
                <p>
                  Jl. Moh. Toha Km 6 Bandung, Jawa Barat, Indonesia <br/>
                  Tel: 022 - 5202930, Faksimili : 022 - 5205881
                </p>
              </div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="footer-widget widget-1">
                <div>
                  <h3>PT. Panasia Filament Inti Tbk.</h3>
                </div>
                <p>
                  Factory: Jl. Cisirung No. 95 Bandung, Jawa Barat, Indonesia<br/>
                  Office: Jl. Moh. Toha Km 6 Bandung, Jawa Barat, Indonesia <br/>
                  Tel: 022 - 6034123, Faksimili : 022 - 5205881
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="copy-right text-center">
                Copyright Panasia Group | All Rights Reserved
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
