import React from "react";

const History = () => {
  
  return (
    <>
      <div className="history-area sec-p-100">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>Riwayat Singkat</h2>
              <p>
              Perusahaan berdiri dengan nama PT Panasia Cotton Alam Mills berdasarkan akta No. 85 tanggal 31 Desember 1987, dengan lingkup usaha di bidang industri pemintalan benang tekstil, pertenunan, processing, dan perdagangan umum dan mulai berproduksi komersil tahun 1988.
              </p>
            </div>
            <div className="col-md-6">
              <h2 className="eng-version">History</h2>
              <p className="eng-version">
              The Company was incorporated under the name PT Panasia Cotton Alam Mills based on notarial deed No. 85 on December 31, 1987, with business in the field of industry of textile yarn spinning, weaving, processing, and general trading and started commercial production in 1988. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default History;
