import React from "react";

const Mainslider = () => {
  return (
    <>
      <div className="main-image pafi-main-image">
          <div className="container">
            <h2>PT Panasia Filament Inti Tbk.</h2>
            <hr />
            <p>
              Factory: Jl. Cisirung No. 95 Bandung, Jawa Barat, Indonesia<br/>
              Office: Jl. Moh. Toha Km 6 Bandung, Jawa Barat, Indonesia <br/>
              Tel: 022 - 6034123, Faksimili : 022 - 5205881
            </p>
        </div>
      </div>
    </>
  );
};

export default Mainslider;
