import React from "react";

import img from "../../img/PAFI/struktur organisasi.png";

const OrganizationChart = () => {
  return (
    <>
      <div className="project-area text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="section-title text-center mb-70">
                <h2>Struktur Organisasi</h2>
                <span><h4>Organization Chart</h4></span>
                <img
                  src={img}
                  alt="project-img"
                  className="img-fluid mt-4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationChart;
